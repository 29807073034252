module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-starter-default","short_name":"starter","start_url":"/","background_color":"#000000","theme_color":"#FFFFFF","display":"minimal-ui","icon":"src/images/gatsby-icon.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"3582c75f3218a03f4777e6c753150b06"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-178763635-1","head":true,"pageTransitionDelay":0,"defer":false,"cookieDomain":"chrisbartlett.dev"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
